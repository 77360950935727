import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Layout, Main, Section } from '@aiya/aiya-web-ui'
import withLocation from '../libs/withLocation'
import { LineAuthorizedPayload, useAuthorizeLineBotMutation } from '@aiya/data-access'
import { Spin } from 'antd'
import { useStateMachine } from 'little-state-machine'
import { GlobalState, updateState } from '../state'

const LinkAccount = ({ data, search, navigate }) => {
  const { code } = search
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState<LineAuthorizedPayload>({} as LineAuthorizedPayload)
  const { state, actions } = useStateMachine<GlobalState>({
    updateState,
  })

  useEffect(() => {
    const [link] = useAuthorizeLineBotMutation({
      onCompleted: ({ authorizeLineBot }) => {
        if (!authorizeLineBot.userId) {
          navigate('')
        }
        setInfo(authorizeLineBot)

        actions.updateState({
          authorizeLineBot,
        })

        setLoading(false)
      },
    })

    if (code && !!!state.authorizeLineBot) {
      setLoading(true)
      link({
        variables: {
          code,
          redirectUri: `${window.location.origin}${window.location.pathname}`,
        },
      })
    }
  }, [code])

  useEffect(() => {
    if (state.authorizeLineBot) {
      setInfo(state.authorizeLineBot)
    }
  }, [state.authorizeLineBot])

  if (loading) {
    return <Spin />
  }

  return (
    <Layout>
      <Main>
        <Section>
          <h1>{info.displayName}</h1>
          <div>botId: {info.userId}</div>
          <div>basicId: {info.basicId}</div>
        </Section>
      </Main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default withLocation(LinkAccount)
