import { LineAuthorizedPayload } from '@aiya/data-access'

export type GlobalState = {
  authorizeLineBot: LineAuthorizedPayload
}

export function updateState<T extends GlobalState, K>(state: T, payload: K): T {
  return {
    ...state,
    ...payload,
  }
}
